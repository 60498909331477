import GuestLayout from 'components/GuestLayout'
import AsyncRoute, { usePreloadPage } from 'components/AsyncRoute'
import { Redirect, Switch } from 'react-router-dom'

export interface GuestRoutesProps {}

function GuestRoutes({}: GuestRoutesProps) {
  usePreloadPage(['guest/Login', 'guest/SignUp', 'guest/ForgotPassword', 'guest/ResetPassword'])

  return (
    <GuestLayout>
      <Switch>
        <AsyncRoute exact path='/signup' page='guest/SignUp' />
        <AsyncRoute exact path='/forgot-password' page='guest/ForgotPassword' />
        <AsyncRoute exact path='/reset-password' page='guest/ResetPassword' />
        <AsyncRoute exact path='/login' page='guest/Login' />
        <AsyncRoute exact path='/confirm' page='guest/ConfirmEmail' />
        <Redirect to='/login' />
      </Switch>
    </GuestLayout>
  )
}

export default GuestRoutes
