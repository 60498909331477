import { CSSProperties, memo, ReactNode, useMemo } from 'react'
import { UserLayoutLink, UserLayoutSC, UserLayoutSCProps } from 'components/ClientLayout/styled'
import LayoutMenu from 'components/ui/LayoutMenu'
import { Row } from 'components/ui/Grid'
import Dropdown from 'components/ui/Dropdown'
import { authEventEmitter } from 'pages'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { ReactComponent as ProductsSVG } from 'components/ClientLayout/assets/products.svg'
import Avatar from 'components/ui/Avatar'
import { margin } from 'utils/styling/indents'
import NotificationBell from 'components/NotificationBell'
import config from 'lib/config'
import { useUserQuery } from 'components/NotificationBell/hooks'

export interface ClientLayoutProps extends UserLayoutSCProps {
  className?: string
  style?: CSSProperties
  children?: ReactNode
}

function ClientLayout({ className, style, children }: ClientLayoutProps) {
  const history = useHistory()
  const match = useRouteMatch({ path: '/product/:id' })
  const { data } = useUserQuery()

  const actions = useMemo(
    () => [
      {
        label: 'General Info',
        onClick: () => history.push('/settings/general'),
      },
      {
        label: 'Body info',
        onClick: () => history.push('/settings/body-information'),
      },
      <hr />,
      {
        label: config.legalPage.termsOfUse.label,
        onClick: () => window.open(config.legalPage.termsOfUse.link, '_blank'),
      },
      {
        label: config.legalPage.privacyPolicy.label,
        onClick: () => window.open(config.legalPage.privacyPolicy.link, '_blank'),
      },
      <hr />,
      {
        label: 'Logout',
        onClick: () => authEventEmitter.emit('logout'),
      },
    ],
    []
  )
  return (
    <UserLayoutSC className={className} style={style} $bgWhite={!!match}>
      <LayoutMenu
        logoHref='/dashboard'
        menu={
          <Row>
            <UserLayoutLink activeClassName='active' to='/products'>
              <ProductsSVG /> Products
            </UserLayoutLink>
          </Row>
        }
        actions={
          <Row>
            <NotificationBell style={margin(4, 18)} />
            <Dropdown options={actions}>
              <Avatar
                src={data?.user.image}
                className='avatar'
                size={44}
                firstName={data?.user.first_name}
              />
            </Dropdown>
          </Row>
        }
      />
      <div className='layout-content-wrapper'>
        <div className='layout-content'>{children}</div>
      </div>
    </UserLayoutSC>
  )
}

export default memo(ClientLayout)
