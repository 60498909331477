import { forwardRef, memo } from 'react'
import { ButtonSC } from './styled'
import Spin, { SpinProps } from 'components/ui/Spin'

export type ButtonTypes =
  | 'default'
  | 'table'
  | 'import'
  | 'primary'
  | 'secondary'
  | 'text'
  | 'wrapper'
  | 'upload'
export type ButtonSizes = 'sm' | 'md' | 'lg' | 'content'

export interface ButtonProps
  extends Omit<JSX.IntrinsicElements['button'], 'size' | 'type' | 'ref'> {
  size?: ButtonSizes
  type?: ButtonTypes
  htmlType?: 'button' | 'submit' | 'reset'
  loading?: boolean
  fontSize?: number
}

const defaultSizes: { [k in ButtonTypes]: ButtonSizes } = {
  primary: 'lg',
  secondary: 'lg',
  default: 'sm',
  text: 'content',
  wrapper: 'content',
  table: 'sm',
  import: 'md',
  upload: 'lg',
}

const spinColor: { [key in ButtonTypes]: Ensure<SpinProps['color']> } = {
  primary: 'light',
  secondary: 'light',
  default: 'dark',
  import: 'dark',
  text: 'dark',
  wrapper: 'dark',
  table: 'tableButton',
  upload: 'light',
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(function (
  { size, type = 'default', htmlType = 'button', children, loading, fontSize, ...btnProps },
  ref
) {
  size = size ?? defaultSizes[type] ?? 'md'

  return (
    <ButtonSC
      btnSize={size}
      btnType={type}
      type={htmlType}
      fontSize={fontSize}
      $loading={loading}
      ref={ref}
      {...btnProps}
    >
      {loading && <Spin color={spinColor[type]} />} {children}
    </ButtonSC>
  )
})

export default memo(Button)
