import styled from 'styled-components'
import { AlertType } from 'components/ui/Alert/index'

export interface AlertSCProps {
  type: AlertType
}

export const AlertSC = styled.div<AlertSCProps>`
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.colors.alert.border};
  border-radius: 15px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  padding: 15px;
  font-size: ${({ theme }) => theme.pxToRem(15)};
  background-color: ${({ theme }) => theme.colors.layout.contentBg};

  div & strong,
  & b {
    font-weight: 500;
  }

  .close-btn {
    margin-top: 4px;
  }
`
