import config from 'lib/config'

export function getToken() {
  return sessionStorage.getItem(config.authTokenKey) || localStorage.getItem(config.authTokenKey)
}

export function getRefreshToken() {
  const storage = sessionStorage.getItem(config.authRefreshKey) ? sessionStorage : localStorage
  return [storage.getItem(config.authRefreshKey), storage] as const
}

export function setToken(storage: Storage, token: string, refreshToken: string) {
  storage.setItem(config.authTokenKey, token)
  storage.setItem(config.authRefreshKey, refreshToken)
}

export interface AuthData {
  id: string
  role: string
  iat: number
  exp: number
}

export function getAuthData(token?: string): AuthData | null {
  try {
    const base64 = (token ?? getToken())?.split('.')[1]
    if (!base64) return null

    return JSON.parse(atob(base64))
  } catch (e) {
    console.error(e)
    return null
  }
}

export function cleanAuthData() {
  sessionStorage.removeItem(config.authTokenKey)
  sessionStorage.removeItem(config.authRefreshKey)
  localStorage.removeItem(config.authTokenKey)
  localStorage.removeItem(config.authRefreshKey)
}
