import { CSSProperties, memo, ReactNode, useMemo } from 'react'
import { UserLayoutLink, UserLayoutSC, UserLayoutSCProps } from 'components/ClientLayout/styled'
import LayoutMenu from 'components/ui/LayoutMenu'
import { Row } from 'components/ui/Grid'
import Dropdown from 'components/ui/Dropdown'
import { authEventEmitter } from 'pages'
import { ReactComponent as DashboardSVG } from 'components/AdminLayout/assets/dashboard.svg'
import { ReactComponent as ProductsSVG } from 'components/AdminLayout/assets/products.svg'
import { ReactComponent as UsersSVG } from 'components/AdminLayout/assets/users.svg'
import Avatar from 'components/ui/Avatar'
import { margin } from 'utils/styling/indents'
import NotificationBell from 'components/NotificationBell'
import { useUserQuery } from 'components/NotificationBell/hooks'

export interface AdminLayoutProps extends UserLayoutSCProps {
  className?: string
  style?: CSSProperties
  children?: ReactNode
}

function AdminLayout({ className, style, children }: AdminLayoutProps) {
  const { data } = useUserQuery()
  const actions = useMemo(
    () => [
      {
        label: 'Logout',
        onClick: () => authEventEmitter.emit('logout'),
      },
    ],
    []
  )
  return (
    <UserLayoutSC className={className} style={style}>
      <LayoutMenu
        logoHref='/dashboard'
        menu={
          <Row>
            <UserLayoutLink activeClassName='active' to='/dashboard'>
              <DashboardSVG /> Dashboard
            </UserLayoutLink>
            <UserLayoutLink activeClassName='active' to='/users'>
              <UsersSVG /> Users
            </UserLayoutLink>
            <UserLayoutLink activeClassName='active' to='/requests'>
              <ProductsSVG /> Requests
            </UserLayoutLink>
          </Row>
        }
        actions={
          <Row>
            <NotificationBell style={margin(4, 18)} />
            <Dropdown options={actions}>
              <Avatar
                src={data?.user.image}
                className='avatar'
                size={44}
                firstName={data?.user.first_name}
              />
            </Dropdown>
          </Row>
        }
      />
      <div className='layout-content-wrapper'>
        <div className='layout-content'>{children}</div>
      </div>
    </UserLayoutSC>
  )
}

export default memo(AdminLayout)
