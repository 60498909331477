import pxToRem from 'utils/styling/pxToRem'
import px from 'utils/styling/px'
import when from 'utils/styling/when'
const theme = {
  colors: {
    pagination: {
      colorActive: '#2F80ED',
      pageButtonBg: '#F0F2FE',
      navButton: '#F2F1F6',
      navText: '#8798AD',
      total: '#bfc5d2',
    },
    layoutMenu: {
      border: '#E4E8F0',
      bg: '#FFFFFF',
    },
    tabs: {
      border: '#E4E8F0',
      primaryActive: '#2F80ED',
      secondaryActive: '#F2C057',
      notActiveText: '#9296A4',
    },
    multiselect: {
      shadow: 'rgba(0, 0, 0, 0.25)',
      border: 'rgba(46, 91, 255, 0.08)',
      bg: '#FFFFFF',
      active: '#2F80ED',
      hr: '#EEF2FF',
    },
    spin: {
      dark: '#093CF7',
      tableButton: '#d0d6ed',
      light: '#ffffff',
    },
    layout: {
      bg: '#F6F5FB',
      contentBg: '#ffffff',
      link: '#BFC5D2',
      linkActive: '#7482D8',
    },
    dropdown: {
      bg: '#ffffff',
      border: 'rgba(46, 91, 255, 0.08)',
    },
    button: {
      default: '#CDD9EB',
      defaultHovered: '#dce5f1',
      defaultActive: '#C4D3E9',

      primary: '#2F80ED',
      primaryHovered: '#5299ff',
      primaryActive: '#2263c1',

      secondary: '#F2C057',
      secondaryHovered: '#fcc45e',
      secondaryActive: '#f1c362',

      error: '#f10f10',
      errorHovered: '#f10f10',
      errorActive: '#f10f10',

      upload: '#E6ECF5',
      uploadHovered: '#E6ECF5',
      uploadActive: '#E6ECF5',

      call: '#EAEFFF',
      callHovered: '#E6ECF5',
      callActive: '#E6ECF5',

      import: '#FFFFFF',
      importHovered: '#FFFFFF',
      importActive: '#FFFFFF',
    },
    input: {
      bg: '#f9faff',
      border: '#E0E7FF',
      active: '#2F80ED',
      placeholder: '#8798AD',

      bgDisabled: '#fcfdff',
      borderDisabled: '#f0f3ff',
      placeholderDisabled: '#c0c3ca',
    },
    colorPicker: {
      border: '#E0E7FF',
      text: '#2E384D',
      bg: '#f9faff',
    },
    search: {
      bg: '#ffffff',
    },
    toggle: {
      dot: '#ffffff',
      primaryOn: '#2F80ED',
      secondaryOn: '#F2C057',
      off: '#cfd6de',
      subscriptionDuration: '#2e384d',
    },
    switch: {
      border: '#E0E7FF',
      bg: '#ebefff',
      active: '#2F80ED',
      disabled: '#8d94a6',
    },
    error: '#f10f10',
    text: {
      primary: '#2E384D',
      secondary: '#FFFFFF',
      upload: '#556D90',
      button: '#556D91',
      label: '#B0BAC9',
      date: '#B0BAC9',
      active: '#F2C057',
      subscriptionDiscount: '#f44b4b',
      subscriptionPlanStarter: '#33ac2e',
      subscriptionPlanScalingUp: '#8c54ff',
      subscriptionPlanProfessional: '#2e5bff',
      subscriptionPlanDurationOldText: '#b0bac9',
      actionTextButton: '#2f80ed',
    },
    title: {
      section: '#8798ad',
      package: '#33AC2E',
      label: '#B0BAC9',
    },
    link: {
      primary: '#2f80ed',
      active: '#2161b3',
      hovered: '#5aa0ff',
      visited: '#1f59a7',
    },
    notificationBar: {
      bgInfo: '#485163',
      bgSuccess: '#33AC2E',
      bgDanger: '#D70000',
    },
    alert: {
      border: '#eff2ff',
    },
    notes: {
      hr: '#e4e8f0',
    },
    callScript: {
      border: '#2F80ED',
    },
    activityLog: {
      splitterBorder: '#e4e8f0',
    },
    files: {
      progressBar: '#2F80ED',
      progressBorder: '#E4E8F0',
      hr: '#E4E8F0',
      tableHr: '#E4E8F0',
      trHover: '#F5F7FF',
      bgTextButton: '#FFFFFF',
    },
    round: {
      bg: '#FFFFFF',
      color: '#F2C057',
      hoverBg: '#FEF9EE',
    },
    easyFormSettings: {
      bgHtmlCode: '#F5F5F7',
    },
    billingSettings: {
      borderCardPreview: '#E0E7FF',
      colorTh: '#BFC5D2',
    },
    usersSettings: {
      colorTh: '#BFC5D2',
      colorDisabled: '#979ca6',
    },
    buyers: {
      fontTh: '#BFC5D2',
    },
    avatar: {
      text: '#ffffff',
      bg: '#cdd9eb',
    },
    card: {
      planCardActive: 'rgba(46, 91, 255, 1)',
    },
    loadPhoto: {
      mask: 'rgba(0, 17, 80, 0.6)',
    },
    badge: {
      default: '#FFFFFF',
      defaultBg: '#8097B1',

      new: '#2E5BFF',
      newBg: '#CCF3F6',

      success: '#61DE08',
      successBg: '#F7FDF2',

      danger: '#F10F10',
      dangerBg: '#fff7f7',

      invited: '#2E384D',
      invitedBg: '#E5E8ED',

      sent: '#00C1D4',
      sentBg: '#CCF3F6',

      wait: '#F7C137',
      waitBg: '#FDF3D7',

      active: '#FF6B00',
      activeBg: '#FEF9EF',

      completed: '#61DE08',
      completedBg: '#F7FDF3',

      action_required: '#F10F10',
      action_requiredBg: '#FDF3F3',

      missing_media: '#FF6B00',
      missing_mediaBg: '#FEF9EE',
    },
    quantityHint: {
      textColor: '#ffffff',
      bgColor: '#8097b1',
    },
    datePicker: {
      bgColor: '#f4f6ff',
    },
    filterModels: {
      bgColor: '#ffffff',
      border: 'rgba(46, 91, 255, 0.08)',
      shadow: '',
    },
    line: {
      default: '#CDD9EB',
      table: '#eef2ff',
    },
    monthPicker: {
      border: '#2F80ED',
      bgSelected: '#2E5BFF',
      colorSelected: '#FFFFFF',
    },
  },
  font: {
    default: `'Rubik', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`,
  },
  headerMenuHeight: pxToRem(80),

  /** Helpers */
  px,
  pxToRem,
  when,
}

export default theme
