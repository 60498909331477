import ModelLayout from 'components/ModelLayout'
import AsyncRoute from 'components/AsyncRoute'
import { Redirect, Switch } from 'react-router-dom'

export interface AdminRoutesProps {}

function ModelRoutes({}: AdminRoutesProps) {
  return (
    <ModelLayout>
      <Switch>
        <AsyncRoute path='/settings' page='model/Settings' />
        <AsyncRoute exact path='/products' page='model/Products' />
        <Redirect to='/products' />
      </Switch>
    </ModelLayout>
  )
}

export default ModelRoutes
