import { ApolloError } from '@apollo/client'
import { get } from 'lodash-es'

export const getApolloErrorMessage = (e?: ApolloError | Error) =>
  get(e, 'graphQLErrors.0.message') ||
  get(e, 'networkError.result.errors.0.message') ||
  get(e, 'message')

export const getApolloErrorCode = (e: ApolloError | Error) => {
  return (
    get(e, 'graphQLErrors.0.extensions.code') ||
    get(e, 'extensions.code') ||
    get(e, 'networkError.result.errors.0.extensions.code')
  )
}
