import { forwardRef, memo } from 'react'

export interface ImageProps extends Omit<JSX.IntrinsicElements['img'], 'ref' | 'alt'> {
  alt: string
}

const Image = forwardRef<HTMLImageElement, ImageProps>(function (props, ref) {
  return <img {...props} ref={ref} />
})

export default memo(Image)
