import styled from 'styled-components'
import { NotificationBarTypes } from 'components/ui/NotificationBar/index'
import choose from 'utils/choose'
import { LinkSC } from 'components/ui/Link/styled'

export interface NotificationBarSCProps {
  type: NotificationBarTypes
}

export const NotificationBarSC = styled.div<NotificationBarSCProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  line-height: ${({ theme }) => theme.pxToRem(27.6)};
  font-size: ${({ theme }) => theme.pxToRem(12)};
  letter-spacing: ${({ theme }) => theme.pxToRem(1.13)};
  font-weight: ${({ type }) =>
    choose(type, {
      danger: 500,
      success: 500,
      info: 400,
    })};

  & b,
  & strong {
    font-weight: 500;
  }

  background-color: ${({ type, theme }) =>
    choose(type, {
      danger: theme.colors.notificationBar.bgDanger,
      success: theme.colors.notificationBar.bgSuccess,
      info: theme.colors.notificationBar.bgInfo,
    })};

  &,
  a,
  ${LinkSC} {
    color: ${({ theme }) => theme.colors.text.secondary};
  }
  a,
  ${LinkSC} {
    text-decoration: underline;
  }
`
