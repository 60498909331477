import GuestRoutes from 'routes/guest'
import useCreateApollo from 'lib/apollo'
import { ApolloProvider } from '@apollo/client'
import { ThemeProvider } from 'styled-components'
import theme from 'lib/theme'
import { BrowserRouter, useLocation } from 'react-router-dom'
import { createContext, useEffect, useMemo, useState } from 'react'
import { cleanAuthData, getAuthData } from 'utils/auth'
import { noop } from 'lodash-es'
import Popups from 'components/Popups'
import AdminRoutes from 'routes/admin'
import ClientRoutes from 'routes/client'
import ModelRoutes from 'routes/model'
import EventEmitter from 'eventemitter3'

const authData = getAuthData()
export const authEventEmitter = new EventEmitter<{ logout(): void }>()
export const AuthDataContext = createContext({
  data: authData,
  setAuth: noop,
})

function App() {
  const [auth, setAuth] = useState(authData)
  const apollo = useCreateApollo()
  useEffect(() => {
    authEventEmitter.on('logout', () => {
      cleanAuthData()
      setAuth(null)
      apollo.clearStore()
    })
  }, [])

  return (
    <AuthDataContext.Provider value={useMemo(() => ({ data: auth, setAuth }), [auth])}>
      <BrowserRouter>
        <PageTitle />
        <ThemeProvider theme={theme}>
          <ApolloProvider client={apollo}>
            <Popups />
            {!auth ? (
              <GuestRoutes />
            ) : auth.role.toLowerCase() === 'admin' ? (
              <AdminRoutes />
            ) : auth.role.toLowerCase() === 'model' ? (
              <ModelRoutes />
            ) : (
              <ClientRoutes />
            )}
          </ApolloProvider>
        </ThemeProvider>
      </BrowserRouter>
    </AuthDataContext.Provider>
  )
}

const titleElem = document.head.querySelector('title') as HTMLTitleElement
function setTitle(title: string) {
  titleElem.innerText = title
}

function PageTitle() {
  const location = useLocation()
  const titles: Array<[string | RegExp, string]> = [
    ['/login', 'Login page | Reflekt Me'],
    ['/signup', 'Registration page | Reflekt Me'],
    ['/forgot-password', 'Forgot password page | Reflekt Me'],
    ['/reset-password', 'Reset password page | Reflekt Me'],
    ['/confirm', 'Confirm email page | Reflekt Me'],
    ['/dashboard', 'Dashboard page | Reflekt Me'],
    ['/users/clients', 'Clients page | Reflekt Me'],
    ['/users/models', 'Models page | Reflekt Me'],
    ['/requests/integrations', 'Requests integrations page | Reflekt Me'],
    ['/requests/models', 'Requests models page | Reflekt Me'],
    ['/settings/general', 'General information page | Reflekt Me'],
    ['/settings/body-information', 'Body information page | Reflekt Me'],
    ['/settings/widget', 'Widget settings page | Reflekt Me'],
    ['/products', 'Products page | Reflekt Me'],
    ['/product', 'Product page | Reflekt Me'],
  ]
  useEffect(() => {
    for (const [template, title] of titles) {
      if (typeof template === 'string' && location.pathname.startsWith(template)) {
        setTitle(title)
        return
      }
      if (template instanceof RegExp && template.test(location.pathname)) {
        setTitle(title)
        return
      }
    }
    setTitle('Reflekt Me')
  }, [location.pathname])
  return null
}

export default App
