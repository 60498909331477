import styled from 'styled-components'
import { ButtonSizes, ButtonTypes } from 'components/ui/Button/index'
import choose from 'utils/choose'
import { SpinSC } from 'components/ui/Spin/styled'

export interface ButtonSCProps {
  btnType: ButtonTypes
  btnSize: ButtonSizes
  fontSize?: number
  $loading?: boolean
}

export const ButtonSC = styled.button<ButtonSCProps>`
  position: relative;
  cursor: pointer;
  pointer-events: ${({ $loading }) => ($loading ? 'none' : 'initial')};
  border: ${({ btnType, theme }) =>
    choose(btnType, {
      default: 'none',
      import: `1px solid ${theme.colors.input.border}`,
      primary: 'none',
      secondary: 'none',
      text: 'none',
      wrapper: 'none',
      table: `1px solid ${theme.colors.input.border}`,
      upload: `1px solid ${theme.colors.input.border}`,
    })};
  border-radius: ${({ btnType }) =>
    choose(btnType, {
      default: 5,
      import: 5,
      primary: 4,
      secondary: 4,
      text: 0,
      wrapper: 0,
      table: 5,
      upload: 5,
    })}px;

  font-weight: ${({ btnType }) =>
    choose(btnType, {
      default: 400,
      primary: 500,
      import: 400,
      secondary: 500,
      text: 500,
      wrapper: 'inherit',
      table: 400,
      upload: 400,
    })};

  font-size: ${({ btnType, theme, fontSize }) =>
    fontSize
      ? theme.pxToRem(fontSize)
      : choose(btnType, {
          default: theme.pxToRem(13),
          primary: theme.pxToRem(15),
          secondary: theme.pxToRem(15),
          import: theme.pxToRem(15),
          text: theme.pxToRem(15),
          wrapper: 'inherit',
          table: theme.pxToRem(13),
          upload: theme.pxToRem(13),
        })};

  color: ${({ btnType, theme }) =>
    choose(btnType, {
      default: theme.colors.text.button,
      primary: theme.colors.text.secondary,
      secondary: theme.colors.text.secondary,
      text: theme.colors.button.primary,
      import: 'inherit',
      wrapper: 'inherit',
      table: theme.colors.text.primary,
      upload: theme.colors.text.upload,
    })};

  background-color: ${({ btnType, theme }) =>
    choose(btnType, {
      default: theme.colors.button.default,
      primary: theme.colors.button.primary,
      secondary: theme.colors.button.secondary,
      import: theme.colors.button.import,
      text: 'unset',
      wrapper: 'unset',
      upload: theme.colors.button.upload,
      table: 'unset',
    })};

  height: ${({ btnSize, theme }) =>
    choose(btnSize, {
      sm: theme.pxToRem(30),
      md: theme.pxToRem(36),
      lg: theme.pxToRem(40),
      content: 'auto',
    })};

  padding: ${({ btnSize, theme, $loading }) =>
    choose(btnSize, {
      sm: [0, theme.pxToRem(13), 0, theme.pxToRem($loading ? 30 : 13)].join(' '),
      md: [0, theme.pxToRem(15), 0, theme.pxToRem($loading ? 30 : 15)].join(' '),
      lg: [0, theme.pxToRem(40)].join(' '),
      content: '0',
    })};

  &:hover {
    background-color: ${({ btnType, theme }) =>
      choose(btnType, {
        default: theme.colors.button.defaultHovered,
        primary: theme.colors.button.primaryHovered,
        secondary: theme.colors.button.secondaryHovered,
        import: theme.colors.button.importHovered,
        text: 'unset',
        wrapper: 'unset',
        table: theme.colors.button.defaultHovered,
        upload: theme.colors.button.uploadHovered,
      })};

    color: ${({ btnType, theme }) =>
      choose(btnType, {
        default: theme.colors.text.button,
        primary: theme.colors.text.secondary,
        secondary: theme.colors.text.secondary,
        text: theme.colors.button.primaryHovered,
        import: 'inherit',
        wrapper: 'inherit',
        table: theme.colors.text.primary,
        upload: theme.colors.text.upload,
      })};
  }

  ${({ btnType }) =>
    btnType === 'wrapper'
      ? 'font: inherit;text-transform: inherit;letter-spacing: inherit;word-spacing: inherit;'
      : ''}

  &:active,
  &:focus {
    background-color: ${({ btnType, theme }) =>
      choose(btnType, {
        default: theme.colors.button.defaultActive,
        primary: theme.colors.button.primaryActive,
        secondary: theme.colors.button.secondaryActive,
        import: theme.colors.button.importActive,
        text: 'unset',
        wrapper: 'unset',
        table: theme.colors.button.defaultActive,
        upload: theme.colors.button.uploadActive,
      })};

    color: ${({ btnType, theme }) =>
      choose(btnType, {
        default: theme.colors.text.button,
        primary: theme.colors.text.secondary,
        secondary: theme.colors.text.secondary,
        text: theme.colors.button.primaryActive,
        import: 'inherit',
        wrapper: 'inherit',
        table: theme.colors.text.primary,
        upload: theme.colors.text.upload,
      })};
  }

  &:disabled,
  &[disabled] {
    pointer-events: none;
    opacity: 0.6;
  }

  ${SpinSC} {
    position: absolute;
    top: 50%;
    transform: translate(calc(-100% - 5px), -50%);
  }
`
