import styled from 'styled-components'

export interface GuestLayoutSCProps {}

export const GuestLayoutSC = styled.div<GuestLayoutSCProps>`
  height: 100%;
  background: url('/assets/guest-layout.jpeg') no-repeat center right;
  background-size: cover;

  .content {
    display: inline-block;
    margin: 80px 0 60px 112px;
    width: 406px;
  }
`
