import styled from 'styled-components'
import { ButtonSC } from 'components/ui/Button/styled'
import { DropdownSC } from 'components/ui/Dropdown/styled'
import { LineSC } from 'components/ui/Line/styled'

export interface NotificationBellSCProps {}

export const NotificationCircleSC = styled.div<NotificationBellSCProps>`
  z-index: 2;
  background-color: ${({ theme }) => theme.colors.button.secondary};
  border-radius: 50%;
  height: ${({ theme }) => theme.pxToRem(12)};
  width: ${({ theme }) => theme.pxToRem(12)};
  border: 3px solid ${({ theme }) => theme.colors.layout.contentBg};
`

export const NotificationBellSC = styled.div<NotificationBellSCProps>`
  ${ButtonSC} {
    ${NotificationCircleSC} {
      position: absolute;
      right: -1px;
      top: -3px;
    }
  }
  ${DropdownSC} {
    padding: 4px 4px 4px 0;
  }
  .messageRow {
    padding: 10px 0 10px;
  }
  .notifications {
    max-height: ${({ theme }) => theme.pxToRem(310)};
    width: ${({ theme }) => theme.pxToRem(305)};
    overflow: hidden auto;
    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.colors.button.secondary};
      border-radius: 5px;
    }
    ${NotificationCircleSC} {
      display: block;
      margin-left: 10px;
      margin-top: 8px;
      border: none;
      height: ${({ theme }) => theme.pxToRem(6)};
      width: ${({ theme }) => theme.pxToRem(6)};
    }
  }
  .notificationRow {
    padding: 0;
    border: none;
    background: none;
    display: block;
    text-align: left;
    &:last-child {
      ${LineSC} {
        display: none;
      }
    }
    &:hover {
      transition: 0.5s;
      cursor: pointer;
    }
  }
  .tag {
    width: ${({ theme }) => theme.pxToRem(30)};
  }
  .message {
    font-style: normal;
    line-height: 22px;
    width: ${({ theme }) => theme.pxToRem(267)};
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-weight: 500;
    font-size: 15px;
    color: ${({ theme }) => theme.colors.text.primary};
    margin-right: 20px;
  }
  .read {
    ${NotificationCircleSC} {
      display: none;
    }
    .message {
      color: #8798ad;
      font-weight: normal;
    }
  }
`
