import { memo } from 'react'
import { TitleSC, TitleSCProps } from './styled'

export type TitleTypes = 'main' | 'package' | 'section' | 'label'

export interface TitleProps
  extends Omit<JSX.IntrinsicElements['span'], 'ref'>,
    Partial<TitleSCProps> {
  seo?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span'
}

function Title({ type = 'main', seo, children, ...titleProps }: TitleProps) {
  return (
    <TitleSC type={type} as={seo} {...titleProps}>
      {children}
    </TitleSC>
  )
}

export default memo(Title)
