import styled, { keyframes } from 'styled-components'
import { AlertSC } from 'components/ui/Alert/styled'
import NotificationBar from 'components/ui/NotificationBar'

const showAlertAnimation = keyframes`
  from {
    transform: translate(-50%, -50vh);
  }
  
  to {
    transform: translate(-50%, 0);
  }
`

export const PopupsAlertsSC = styled.div`
  position: absolute;
  top: 40px;
  left: 50vw;
  width: 0;
  height: 0;
  padding: 0;
  overflow: visible;
  z-index: 11;

  ${AlertSC} {
    width: 855px;
    margin-bottom: 15px;
    animation: ${showAlertAnimation} 0.4s ease forwards;
  }
`

export const PopupsNotificationSC = styled(NotificationBar)`
  width: 100vw;
  position: sticky;
  top: 0;
  z-index: 11;
`
