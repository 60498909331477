import { CSSProperties, memo } from 'react'
import { AlertSC, AlertSCProps } from './styled'
import { Row, RowProps } from 'components/ui/Grid'
import Image from 'components/ui/Image'
import Button from 'components/ui/Button'
import { TextWrapSC } from 'components/ui/TextWrap/styled'

export type AlertType = 'warning' | 'info' | 'error' | 'success'

export interface AlertProps extends RowProps, Partial<AlertSCProps> {
  className?: string
  style?: CSSProperties
  closable?: boolean
  onClose?: () => void
}

const typeIconData: { [k in AlertType]: { src: string; alt: string } } = {
  warning: { src: '/assets/alert-icons/warning.svg', alt: 'notification warning icon' },
  error: { src: '/assets/alert-icons/error.svg', alt: 'notification error icon' },
  success: { src: '/assets/alert-icons/success.svg', alt: 'notification success icon' },
  info: { src: '/assets/alert-icons/info.svg', alt: 'notification information icon' },
}

function Alert({
  type = 'info',
  closable = true,
  className,
  children,
  onClose,
  style,
  ...rest
}: AlertProps) {
  return (
    <AlertSC type={type} className={className} style={style} {...rest}>
      <Row align='start' gap={16}>
        <Image src={typeIconData[type].src} alt={typeIconData[type].alt} />
        <TextWrapSC>{children}</TextWrapSC>
        {closable && (
          <Button className='close-btn' type='wrapper' onClick={onClose}>
            <Image src={'/assets/alert-icons/close.svg'} alt={'notification close button'} />
          </Button>
        )}
      </Row>
    </AlertSC>
  )
}

export default memo(Alert)
