import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

export interface UserLayoutSCProps {
  $bgWhite?: boolean
}

export const UserLayoutSC = styled.div<UserLayoutSCProps>`
  min-height: 100%;
  background-color: ${({ theme, $bgWhite }) =>
    $bgWhite ? theme.colors.layout.contentBg : theme.colors.layout.bg};

  .avatar {
    border: none;
    cursor: pointer;

    .rc-dropdown-arrow {
      right: 19px !important;
    }
  }

  .layout-content-wrapper {
    padding: 0 ${({ theme }) => theme.pxToRem(30)};
  }

  .layout-content {
    max-width: 1350px;
    margin: 0 auto;
  }
`

export const UserLayoutLink = styled(NavLink)`
  display: inline-flex;
  align-items: center;

  color: ${({ theme }) => theme.colors.layout.link};
  svg path {
    fill: ${({ theme }) => theme.colors.layout.link};
  }
  text-decoration: none;
  margin-right: ${({ theme }) => theme.pxToRem(40)};
  &:last-child {
    margin-right: 0;
  }

  svg {
    margin-right: ${({ theme }) => theme.pxToRem(8)};
  }

  &.active {
    color: ${({ theme }) => theme.colors.button.secondary};

    svg path {
      fill: ${({ theme }) => theme.colors.button.secondary};
      stroke: ${({ theme }) => theme.colors.button.secondary};
    }
  }
`
