import ClientLayout from 'components/ClientLayout'
import AsyncRoute from 'components/AsyncRoute'
import { Redirect, Switch } from 'react-router-dom'

export interface UserRoutesProps {}

function ClientRoutes({}: UserRoutesProps) {
  return (
    <ClientLayout>
      <Switch>
        <AsyncRoute path='/settings' page='client/Settings' />
        <AsyncRoute exact path='/dashboard' page='client/Dashboard' />
        <AsyncRoute exact path='/products' page='client/Products' />
        <AsyncRoute path='/product/:id' page='client/Product' />
        <Redirect to='/dashboard' />
      </Switch>
    </ClientLayout>
  )
}

export default ClientRoutes
