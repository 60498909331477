import React, { CSSProperties, memo, MouseEventHandler, useCallback, useMemo } from 'react'
import { NotificationBellSC, NotificationBellSCProps, NotificationCircleSC } from './styled'
import { ReactComponent as BellSVG } from 'components/svg/bell.svg'
import Dropdown from 'components/ui/Dropdown'
import Button from 'components/ui/Button'
import {
  useFetchMoreNotificationOnScroll,
  useNotificationQuery,
  USER_Q,
  useReadNotification,
  useSetHasNotificationFalse,
  useUserQuery,
} from './hooks'
import { Row } from 'components/ui/Grid'
import cn from 'utils/styling/cn'
import Line from 'components/ui/Line'
import Spin from 'components/ui/Spin'
import Title from 'components/ui/Title'
import { useHistory } from 'react-router-dom'
import { gql, NetworkStatus } from '@apollo/client'
import { marginTop } from 'utils/styling/indents'

export interface NotificationBellProps extends NotificationBellSCProps {
  className?: string
  style?: CSSProperties
}
const QUERY_QUANTITY = 40
function NotificationBell({ className, style, ...rest }: NotificationBellProps) {
  const history = useHistory()
  const [
    notificationQuery,
    { data: notificationData, loading, refetch: fetchMoreNotifications, networkStatus },
  ] = useNotificationQuery({
    limit: QUERY_QUANTITY,
  })
  const handleOnPopupScroll = useFetchMoreNotificationOnScroll(
    (page) =>
      fetchMoreNotifications &&
      fetchMoreNotifications({
        input: {
          limit: QUERY_QUANTITY,
          page,
        },
      }),
    useMemo(
      () =>
        Number(notificationData?.notificationList.data.length) <
        Number(notificationData?.notificationList.totals.total),
      [notificationData]
    )
  )

  const { data: userData } = useUserQuery()
  const [setHasNotificationFalse] = useSetHasNotificationFalse()
  const [markAsRead] = useReadNotification()
  const handleVisibleChange = useCallback(
    async (visible) => {
      if (visible) {
        if (!notificationData?.notificationList.data) {
          notificationQuery({
            variables: {
              input: {
                limit: QUERY_QUANTITY,
              },
            },
          })
        }

        if (userData?.user.has_notification) {
          setHasNotificationFalse({
            variables: { id: userData?.user.id },
            update(cache) {
              cache.writeQuery({
                query: USER_Q,
                data: {
                  user: { id: userData?.user.id, __typename: 'User', has_notification: false },
                },
              })
            },
          })
        }
      }
    },
    [userData, notificationData]
  )

  const handleRead: MouseEventHandler<HTMLButtonElement> = useCallback(async (e) => {
    const link = e.currentTarget.dataset.link!
    const id = e.currentTarget.dataset.id!

    await markAsRead({
      variables: { id },
      update(cache) {
        cache.writeFragment({
          fragment: gql`
            fragment myNotification on Notification {
              is_read
            }
          `,
          data: {
            id,
            __typename: 'Notification',
            is_read: true,
          },
        })
      },
    })
    if (link) history.push(link)
  }, [])

  return (
    <NotificationBellSC className={className} style={style} {...rest}>
      <Dropdown
        onVisibleChange={handleVisibleChange}
        align={{
          targetOffset: [-35, 0],
        }}
        content={
          <div className={'notifications'} onScroll={handleOnPopupScroll}>
            {notificationData?.notificationList.data.map((notification) => (
              <button
                className={'notificationRow'}
                key={notification.id}
                onClick={handleRead}
                data-link={notification.meta.link ?? ''}
                data-id={notification.id}
              >
                <Row className={cn('messageRow', { read: notification.is_read })}>
                  <div className={'tag'}>
                    <NotificationCircleSC />
                  </div>
                  <div className={'message'}>
                    {notification.message.replace(/\{([^}]+)}/g, (match, name) => {
                      if (name in notification.meta) return notification.meta[name]
                      return match
                    })}
                  </div>
                </Row>
                <Line type={'table'} />
              </button>
            ))}
            {!notificationData?.notificationList.totals.total && !loading && (
              <Title type={'label'} className={'text-center'}>
                no notifications
              </Title>
            )}
            {(loading || networkStatus === NetworkStatus.fetchMore) && (
              <Row justify={'center'} style={marginTop(8)}>
                <Spin color='dark' />
              </Row>
            )}
          </div>
        }
      >
        <Button type={'wrapper'}>
          <>
            {userData?.user.has_notification && <NotificationCircleSC />}
            <BellSVG />
          </>
        </Button>
      </Dropdown>
    </NotificationBellSC>
  )
}

export default memo(NotificationBell)
