import { CSSProperties, memo } from 'react'
import { LineSC, LineSCProps } from './styled'

export interface LineProps extends Omit<LineSCProps, '$type'> {
  className?: string
  style?: CSSProperties
  type?: LineSCProps['$type']
}

function Line({ className, style, type = 'default' }: LineProps) {
  return <LineSC className={className} style={style} $type={type} />
}

export default memo(Line)
