import { CSSProperties, memo, useCallback, useEffect, useReducer } from 'react'
import { PopupsAlertsSC, PopupsNotificationSC } from './styled'
import { NotificationBarProps } from 'components/ui/NotificationBar'
import Alert, { AlertProps } from 'components/ui/Alert'
import config from 'lib/config'
import { createPortal } from 'react-dom'
import { popupsEE } from 'components/Popups/helpers'

export interface PopupsProps {
  className?: string
  style?: CSSProperties
}

export interface PopupsState {
  notification?: {
    type: NotificationBarProps['type']
    message: NotificationBarProps['children']
    props?: Omit<NotificationBarProps, 'type' | 'children'>
  }
  alerts: Array<{
    uuid: string
    type: AlertProps['type']
    message: AlertProps['children']
    props?: Omit<AlertProps, 'type' | 'children'>
  }>
}

export type PopupsAction =
  | {
      type: 'ALERT'
      data: ArrayType<PopupsState['alerts']>
    }
  | {
      type: 'REMOVE_ALERT'
      uuid: string
    }
  | {
      type: 'NOTIFICATION'
      data: PopupsState['notification']
    }
  | {
      type: 'REMOVE_NOTIFICATION'
    }

function Popups({ className, style, ...rest }: PopupsProps) {
  const popupsRoot = document.getElementById(config.popupsRootId)!
  const [{ notification, alerts }, dispatch] = useReducer(
    (state: PopupsState, action: PopupsAction) => {
      switch (action.type) {
        case 'ALERT':
          return {
            ...state,
            alerts: [action.data].concat(state.alerts),
          }
        case 'REMOVE_ALERT':
          return {
            ...state,
            alerts: state.alerts.filter((alert) => alert.uuid !== action.uuid),
          }
        case 'NOTIFICATION':
          return {
            ...state,
            notification: action.data,
          }
        case 'REMOVE_NOTIFICATION':
          return {
            ...state,
            notification: undefined,
          }
      }
    },
    {
      notification: undefined,
      alerts: [],
    }
  )

  const handleRemoveAlert = useCallback(
    (uuid: string) => () =>
      dispatch({
        type: 'REMOVE_ALERT',
        uuid,
      }),
    []
  )

  useEffect(() => {
    popupsEE.on('action', dispatch)
    return () => {
      popupsEE.off('action', dispatch)
    }
  }, [])

  return createPortal(
    <>
      {notification && (
        <PopupsNotificationSC type={notification.type} {...notification.props}>
          {notification.message}
        </PopupsNotificationSC>
      )}
      <PopupsAlertsSC className={className} style={style} {...rest}>
        {alerts.map(({ uuid, type, message, props }) => (
          <Alert key={uuid} type={type} onClose={handleRemoveAlert(uuid)} {...props}>
            {message}
          </Alert>
        ))}
      </PopupsAlertsSC>
    </>,
    popupsRoot
  )
}

export default memo(Popups)
