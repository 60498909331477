import styled from 'styled-components'

export interface LayoutMenuSCProps {}

export const LayoutMenuSC = styled.div<LayoutMenuSCProps>`
  padding: 0 ${({ theme }) => theme.pxToRem(36)} 0 ${({ theme }) => theme.pxToRem(41)};
  height: ${({ theme }) => theme.pxToRem(80)};
  border-bottom: 1px solid ${({ theme }) => theme.colors.layoutMenu.border};
  background-color: ${({ theme }) => theme.colors.layoutMenu.bg};
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left {
    display: flex;
    align-items: center;
  }

  .menu {
    margin-left: ${({ theme }) => theme.pxToRem(40)};
  }
`
