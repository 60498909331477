import EventEmitter from 'eventemitter3'
import { PopupsAction, PopupsState } from 'components/Popups'
import { v4 } from 'uuid'
import { Dispatch } from 'react'

interface PopupsEvents {
  action: Dispatch<PopupsAction>
}

export const popupsEE = new EventEmitter<PopupsEvents>()

export function popupAlert(
  alert: Omit<ArrayType<PopupsState['alerts']>, 'uuid'>,
  removeIn: number = 7000
) {
  const uuid = v4()
  popupsEE.emit('action', {
    type: 'ALERT',
    data: {
      uuid,
      ...alert,
    },
  })
  if (removeIn) {
    setTimeout(
      () =>
        popupsEE.emit('action', {
          type: 'REMOVE_ALERT',
          uuid,
        }),
      removeIn
    )
  }
}

export function popupNotification(
  notification: PopupsState['notification'],
  removeIn: number = 7000
) {
  popupsEE.emit('action', {
    type: 'NOTIFICATION',
    data: notification,
  })
  if (removeIn) {
    setTimeout(
      () =>
        popupsEE.emit('action', {
          type: 'REMOVE_NOTIFICATION',
        }),
      removeIn
    )
  }
}

export function popupClearNotification() {
  popupsEE.emit('action', {
    type: 'REMOVE_NOTIFICATION',
  })
}
