import {
  cloneElement,
  memo,
  ReactNode,
  useCallback,
  useRef,
  ReactElement,
  isValidElement,
} from 'react'
import { DropdownSC, DropdownSCProps } from './styled'
import RcDropdown from 'rc-dropdown'
import { DropdownProps as RcDropdownProps } from 'rc-dropdown/lib/Dropdown'
import './rc-dropdown.scss'

interface IDropdownProps extends Omit<RcDropdownProps, 'overlay'>, DropdownSCProps {
  forceRender?: boolean
  placement?: 'topLeft' | 'topRight' | 'topCenter' | 'bottomLeft' | 'bottomRight' | 'bottomCenter'
}

export interface DropdownOption extends Omit<JSX.IntrinsicElements['button'], 'ref' | 'value'> {
  label: string
  value?: any
}

export type DropdownProps = IDropdownProps &
  (
    | {
        overlay: RcDropdownProps['overlay']
        options?: never
        content?: never
      }
    | {
        overlay?: never
        options: Array<DropdownOption | ReactElement>
        content?: never
      }
    | {
        overlay?: never
        options?: never
        content?: ReactNode
      }
  )

function Dropdown({ children, overlay, options = [], content, arrow, ...rest }: DropdownProps) {
  arrow = arrow ?? !overlay

  const ref = useRef<HTMLElement>(document.body)
  const getPopupContainer = useCallback(() => ref.current, [])
  const handleClickCapture = useCallback((e: any) => {
    if (e?.currentTarget?.parentElement) {
      const parent = e.currentTarget.parentElement
      ref.current = parent.classList.contains('wrapper') ? parent.parentElement! : parent
    }
  }, [])

  return (
    <RcDropdown
      arrow={arrow ?? !overlay}
      minOverlayWidthMatchTrigger={false}
      animation='slide-up'
      trigger='click'
      placement='bottomRight'
      getPopupContainer={getPopupContainer}
      overlay={
        overlay ?? (
          <div>
            <DropdownSC>
              {options!.map((option, i) => {
                if (isValidElement(option)) return cloneElement(option, { key: '_' + i })
                const { label, value, ...props } = option
                return (
                  <button key={value ?? label ?? i} {...props}>
                    {label}
                  </button>
                )
              })}
              {content}
            </DropdownSC>
            {arrow && <div className='rc-dropdown-arrow' />}
          </div>
        )
      }
      {...rest}
    >
      {cloneElement(children, {
        onClickCapture: handleClickCapture,
      })}
    </RcDropdown>
  )
}

export default memo(Dropdown)
