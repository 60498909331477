import { forwardRef, memo } from 'react'
import { AvatarSC, AvatarSCProps } from './styled'
import Image from 'components/ui/Image'

export interface AvatarProps extends AvatarSCProps, Omit<JSX.IntrinsicElements['div'], 'ref'> {
  src: string | null | undefined
  firstName?: string | null
  border?: boolean
  fontSize?: string | number
  size?: string | number
}

const Avatar = forwardRef<HTMLDivElement, AvatarProps>(function (
  {
    className,
    src,
    style,
    size = 'inherit',
    firstName = '',
    border = false,
    fontSize = 20,
    ...divProps
  },
  ref
) {
  return (
    <AvatarSC $border={border} $size={size} $fontSize={fontSize} ref={ref} {...divProps}>
      {src ? (
        <Image src={src} alt={firstName + ' avatar'} />
      ) : firstName ? (
        firstName[0].toUpperCase()
      ) : null}
    </AvatarSC>
  )
})

export default memo(Avatar)
