import styled, { keyframes } from 'styled-components'

export interface SpinSCProps {}

const rotate = keyframes`
  from {
    transform: rotate(0);
  }
  
  to {
    transform: rotate(360deg);
  }
`

export const SpinSC = styled.div<SpinSCProps>`
  display: inline-block;
  svg {
    animation: ${rotate} 1.3s cubic-bezier(1, 0.99, 0.03, 0.1) infinite;
  }
`
