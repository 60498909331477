import { CSSProperties, memo } from 'react'
import { NotificationBarSC, NotificationBarSCProps } from './styled'

export type NotificationBarTypes = 'danger' | 'success' | 'info'

export interface NotificationBarProps
  extends Omit<JSX.IntrinsicElements['div'], 'ref'>,
    Partial<NotificationBarSCProps> {
  className?: string
  style?: CSSProperties
}

function NotificationBar({
  type = 'info',
  className,
  style,
  children,
  ...rest
}: NotificationBarProps) {
  return (
    <NotificationBarSC type={type} className={className} style={style} {...rest}>
      {children}
    </NotificationBarSC>
  )
}

export default memo(NotificationBar)
