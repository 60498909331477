import * as yup from 'yup'
import { AnyObject, Maybe } from 'yup/lib/types'
import BaseSchema from 'yup/lib/schema'
import { URL_REGEXP } from 'utils/url'
import { ZIP_REGEXPS } from 'utils/zipCode'

/** String helpers **/

declare module 'yup' {
  export class StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends BaseSchema<TType, TContext, TOut> {
    unmask(placeholderChar?: RegExp | string): this
    length(length: number, message?: string): this
    url(url: number, message?: string): this
    zipCode(country: string, message?: string): this
  }
}

yup.addMethod(yup.string, 'unmask', function (placeholderChar: RegExp | string = '_') {
  return this.transform(function (value?: string, originalValue?: string) {
    value = value ?? originalValue
    return value && value.replace(unmaskRegExp(placeholderChar, 'g'), '')
  })
})

yup.addMethod(yup.string, 'length', function (length: number, message?: string) {
  return this.min(length, message).max(length, message)
})

yup.addMethod(yup.string, 'url', function (message?: string) {
  return this.matches(URL_REGEXP, message)
})

yup.addMethod(yup.string, 'zipCode', function (country: string, message?: string) {
  if (!ZIP_REGEXPS[country as keyof typeof ZIP_REGEXPS]) return this
  return this.matches(ZIP_REGEXPS[country as keyof typeof ZIP_REGEXPS], message)
})

function unmaskRegExp(maybeRegExp: string | RegExp, flags?: string) {
  return maybeRegExp instanceof RegExp
    ? maybeRegExp
    : new RegExp(maybeRegExp.split('').join('|'), flags)
}
