import styled from 'styled-components'

export interface AvatarSCProps {
  $fontSize?: string | number
  $size?: string | number
  $border?: boolean
}

export const AvatarSC = styled.div<AvatarSCProps>`
  ${({ $border, theme }) => ($border ? `border: 2px solid ${theme.colors.button.primary};` : '')}
  ${({ $size, theme }) =>
    $size !== 'inherit'
      ? `width: ${theme.pxToRem($size as number)};
  height: ${theme.pxToRem($size as number)};`
      : ''}
  background-color: ${({ theme }) => theme.colors.avatar.bg};
  color: ${({ theme }) => theme.colors.avatar.text};
  border-radius: 100%;
  cursor: pointer;
  font-weight: 600;
  font-size: ${({ theme, $fontSize }) => theme.pxToRem($fontSize as number)};
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    border-radius: 100%;
    ${({ $size, theme }) =>
      $size !== 'inherit'
        ? `width: ${theme.pxToRem($size as number)};
  height: ${theme.pxToRem($size as number)};`
        : ''}
  }
`
