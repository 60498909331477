import { CSSProperties, memo } from 'react'
import { SpinSC, SpinSCProps } from './styled'
import { useTheme } from 'styled-components'
import theme from 'lib/theme'
import { v4 } from 'uuid'
import useInitial from 'hooks/useInitial'

export interface SpinProps extends SpinSCProps {
  className?: string
  style?: CSSProperties
  color?: keyof typeof theme.colors.spin
  size?: 'default'
}

const sizes: { [key in Ensure<SpinProps['size']>]: number } = {
  default: 16,
}

function Spin({ className, style, color = 'dark', size = 'default', ...rest }: SpinProps) {
  const theme = useTheme()
  const width = sizes[size]
  const gradientId = useInitial(v4())

  return (
    <SpinSC className={className} style={style} {...rest}>
      <svg
        width={width}
        height={width}
        viewBox='0 0 17 17'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12.5355 1.85537C11.2058 1.02111 9.72178 0.622803 8.25531 0.622803C5.60807 0.622991 3.01759 1.92031 1.49378 4.29245C0.957975 5.12671 0.601594 6.02252 0.411499 6.93603L1.91474 7.19259C2.07036 6.46883 2.35607 5.75993 2.78026 5.0996C3.97531 3.23946 6.02198 2.12887 8.25531 2.12869C9.48064 2.12869 10.6782 2.47391 11.7187 3.12671C14.7336 5.0181 15.629 8.98083 13.7151 11.96C12.5201 13.8202 10.4734 14.9308 8.24007 14.9308C7.01474 14.9308 5.81721 14.5857 4.77664 13.9329C4.29264 13.6293 3.86693 13.27 3.49531 12.8722L2.34369 13.8597C2.81055 14.3644 3.34769 14.8201 3.95988 15.2043C5.28959 16.0385 6.77359 16.4366 8.24007 16.4366C10.8873 16.4366 13.4778 15.1391 15.0016 12.7672C17.3696 9.08097 16.2656 4.1957 12.5355 1.85537Z'
          fill={`url(#${gradientId})`}
        />
        <defs>
          <radialGradient
            id={gradientId}
            cx='0'
            cy='0'
            r='1'
            gradientUnits='userSpaceOnUse'
            gradientTransform='translate(3.35768 16.4366) rotate(-26.0858) scale(14.3531 14.3743)'
          >
            <stop stopColor={theme.colors.spin[color]} stopOpacity='0.4' />
            <stop offset='1' stopColor={theme.colors.spin[color]} />
          </radialGradient>
        </defs>
      </svg>
    </SpinSC>
  )
}

export default memo(Spin)
