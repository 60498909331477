import styled from 'styled-components'
import { TitleTypes } from 'components/ui/Title/index'
import choose from 'utils/choose'

export interface TitleSCProps {
  type: TitleTypes
  inline?: boolean
  color?: string
}

export const TitleSC = styled.span<TitleSCProps>`
  display: ${({ inline }) => (inline ? 'inline-block' : 'block')};
  margin: 0;

  font-size: ${({ type, theme }) =>
    choose(type, {
      main: theme.pxToRem(28),
      package: theme.pxToRem(22),
      section: theme.pxToRem(13),
      label: theme.pxToRem(12),
    })};

  font-weight: ${({ type }) =>
    choose(type, {
      main: 300,
      package: 300,
      section: 400,
      label: 500,
    })};

  text-transform: ${({ type }) =>
    choose(type, {
      main: 'none',
      package: 'uppercase',
      section: 'uppercase',
      label: 'uppercase',
    })};

  color: ${({ type, color, theme }) =>
    color
      ? color
      : choose(type, {
          main: theme.colors.text.primary,
          package: theme.colors.title.package,
          section: theme.colors.title.section,
          label: theme.colors.title.label,
        })};

  line-height: ${({ theme, type }) =>
    choose(type, {
      main: theme.pxToRem(32),
      package: '100%',
      section: theme.pxToRem(15.4),
      label: theme.pxToRem(14.2),
    })};

  letter-spacing: ${({ theme, type }) =>
    choose(type, {
      main: 'normal',
      package: 'normal',
      section: theme.pxToRem(1.2),
      label: theme.pxToRem(1.13),
    })};
`
