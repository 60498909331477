import styled from 'styled-components'

export interface DropdownSCProps {}

export const DropdownSC = styled.div<DropdownSCProps>`
  background-color: ${({ theme }) => theme.colors.dropdown.bg};
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.colors.dropdown.border};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  font-size: ${({ theme }) => theme.pxToRem(15)};
  position: relative;

  > button {
    cursor: pointer;
    display: block;
    border: none;
    background: none;
    padding: ${({ theme }) => [theme.pxToRem(4.5), theme.pxToRem(18)].join(' ')};
    line-height: ${({ theme }) => theme.pxToRem(21)};
    color: ${({ theme }) => theme.colors.text.primary};

    &:hover {
      color: ${({ theme }) => theme.colors.text.active};
    }

    &:first-child {
      padding-top: ${({ theme }) => theme.pxToRem(10)};
    }

    &:last-child {
      padding-bottom: ${({ theme }) => theme.pxToRem(10)};
    }
  }

  > hr {
    margin: 3px 0;
    height: 1px;
    border: none;
    color: ${({ theme }) => theme.colors.dropdown.border};
    background-color: ${({ theme }) => theme.colors.dropdown.border};
  }
`
