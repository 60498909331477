export default {
  authTokenKey: 'api_key',
  authRefreshKey: 'api_refresh_key',
  defaultDateFormat: 'MM-DD-YYYY',
  supportedDateFormats: [
    {
      label: 'DD-MM-YYYY',
      value: 'DD-MM-YYYY',
    },
    {
      label: 'MM-DD-YYYY',
      value: 'MM-DD-YYYY',
    },
  ],
  modalRootId: 'modal-root',
  popupsRootId: 'popups-root',
  supportedCouponFileType: ['pdf', 'txt', 'png', 'jpg', 'jpeg', 'doc', 'docx'],
  errorCodes: {
    userNotApproved: 'USER_NOT_APPROVED',
    emailNotApproved: 'EMAIL_NOT_APPROVED',
    userRejected: 'USER_REJECTED',
    emailNotConfirm: 'EMAIL_NOT_CONFIRMED',
    unauthenticated: 'UNAUTHENTICATED',
    productNotExist: 'PRODUCT_NOT_EXISTS',
    accessDenied: 'ACCESS_DENIED',
  },
  legalPage: {
    termsOfUse: {
      label: 'Terms of Use',
      link: 'https://www.reflektme.com/terms-of-use',
    },
    privacyPolicy: {
      label: 'Privacy Policy',
      link: 'https://www.reflektme.com/privacy-policy',
    },
  },
}
