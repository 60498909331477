import AsyncRoute from 'components/AsyncRoute'
import { Redirect, Switch } from 'react-router-dom'
import AdminLayout from 'components/AdminLayout'

export interface AdminRoutesProps {}

function AdminRoutes({}: AdminRoutesProps) {
  return (
    <AdminLayout>
      <Switch>
        <AsyncRoute path='/dashboard' page='admin/Dashboard' />
        <AsyncRoute path='/requests/:id/models' page='admin/RequestsModelsInviteModel' />
        <AsyncRoute path='/requests' page='admin/Requests' />
        <AsyncRoute path='/users' page='admin/Users' />
        <Redirect to='/dashboard' />
      </Switch>
    </AdminLayout>
  )
}

export default AdminRoutes
