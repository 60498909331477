import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client'
import {
  Mutation,
  MutationReadNotificationArgs,
  MutationSetHasNotificationFalseArgs,
  NotificationListInput,
  Query,
  QueryNotificationListArgs,
  QueryUserArgs,
} from 'types/schema'
import { useCallback, useRef } from 'react'

export const USER_Q = gql`
  query user {
    user {
      id
      first_name
      image
      has_notification
    }
  }
`

export function useUserQuery() {
  return useQuery<Pick<Query, 'user'>, QueryUserArgs>(USER_Q)
}

export const NOTIFICATIONS_Q = gql`
  query notificationList($input: NotificationListInput!) {
    notificationList(input: $input) {
      data {
        id
        user_id
        message
        is_read
        meta
      }
      totals {
        total
      }
    }
  }
`

export const READ_NOTIFICATION_Q = gql`
  mutation readNotification($id: BigInt!) {
    readNotification(id: $id)
  }
`

export const SET_HAS_NOTIFICATIONS_FALSE_Q = gql`
  mutation setHasNotificationFalse($id: BigInt!) {
    setHasNotificationFalse(id: $id)
  }
`

export function useNotificationQuery(input: NotificationListInput) {
  return useLazyQuery<Pick<Query, 'notificationList'>, QueryNotificationListArgs>(NOTIFICATIONS_Q, {
    variables: { input },
    notifyOnNetworkStatusChange: true,
  })
}

export function useSetHasNotificationFalse() {
  return useMutation<
    Pick<Mutation, 'setHasNotificationFalse'>,
    MutationSetHasNotificationFalseArgs
  >(SET_HAS_NOTIFICATIONS_FALSE_Q)
}

export function useReadNotification() {
  return useMutation<Pick<Mutation, 'readNotification'>, MutationReadNotificationArgs>(
    READ_NOTIFICATION_Q
  )
}

export function useFetchMoreNotificationOnScroll(
  fetchMore: (page: number) => MaybePromise<any>,
  hasMore: boolean
) {
  const pageRef = useRef(0)
  const timerRef = useRef<any>()

  return useCallback(
    (e) => {
      if (!hasMore) return
      clearTimeout(timerRef.current)
      timerRef.current = setTimeout(async () => {
        const container = e.target as HTMLElement
        const halfOfHeightLeftToEnd =
          container.scrollTop + container.clientHeight >= container.scrollHeight - 20
        if (halfOfHeightLeftToEnd) {
          const nextPage = pageRef.current + 1
          await fetchMore(nextPage)
          pageRef.current = Math.max(pageRef.current, nextPage)
        }
      }, 100)
    },
    [hasMore]
  )
}
