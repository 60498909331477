import styled from 'styled-components'
import { ThemeType } from 'types/modules'

export interface LineSCProps {
  $type: 'table' | 'default'
}

const getLineType = function (type: LineSCProps['$type'], theme: ThemeType) {
  switch (type) {
    case 'table':
      return `border-top: 1px solid ${theme.colors.line.table};`
    case 'default':
      return `border-top: 1px solid ${theme.colors.line.default};`
    default:
      return `border-top: 1px solid ${theme.colors.line.default};`
  }
}

export const LineSC = styled.div<LineSCProps>`
  width: 100%;
  display: block;
  ${({ theme, $type }) => getLineType($type, theme)}
`
