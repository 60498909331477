import { CSSProperties, memo, ReactNode } from 'react'
import { GuestLayoutSC, GuestLayoutSCProps } from './styled'

export interface GuestLayoutProps extends GuestLayoutSCProps {
  className?: string
  style?: CSSProperties
  children?: ReactNode
}

function GuestLayout({ className, style, children }: GuestLayoutProps) {
  return (
    <GuestLayoutSC className={className} style={style}>
      <div className='content'>{children}</div>
    </GuestLayoutSC>
  )
}

export default memo(GuestLayout)
