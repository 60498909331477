import { useMemo } from 'react'
import { ApolloClient, ApolloLink, concat, fromPromise, gql, InMemoryCache } from '@apollo/client'
import typePolicies from 'lib/apollo/typePolicies'
import { onError } from '@apollo/client/link/error'
import { createUploadLink } from 'apollo-upload-client'
import { popupAlert } from 'components/Popups/helpers'
import { getApolloErrorCode } from 'utils/apollo'
import { getRefreshToken, getToken, setToken } from 'utils/auth'
import { authEventEmitter } from 'pages'
import config from 'lib/config'
import { Mutation, MutationRefreshTokenArgs } from 'types/schema'

let apolloClient: ApolloClient<any>
let activeUpdateToken: Promise<any> | undefined

const httpLink = (createUploadLink({ uri: process.env.REACT_APP_GRAPHQL_URL }) as any) as ApolloLink

const authMiddleware = new ApolloLink((operation, forward) => {
  return fromPromise(Promise.resolve(activeUpdateToken ?? true))
    .filter(Boolean)
    .flatMap(() => {
      operation.setContext({
        headers: {
          authorization: getToken(),
        },
      })
      return forward(operation)
    })
})

const errorMiddleware = onError(({ networkError, graphQLErrors, forward, operation }) => {
  const errorsToShow = graphQLErrors?.filter(
    (error) =>
      ![
        config.errorCodes.unauthenticated,
        config.errorCodes.emailNotConfirm,
        config.errorCodes.userNotApproved,
        config.errorCodes.emailNotApproved,
        config.errorCodes.userRejected,
        config.errorCodes.productNotExist,
        config.errorCodes.accessDenied,
      ].includes(getApolloErrorCode(error))
  )
  if (errorsToShow?.length) {
    errorsToShow.forEach(console.error)
    popupAlert({
      type: 'error',
      message: errorsToShow[0].message,
    })
  }
  if (!graphQLErrors?.length && networkError) {
    console.error(networkError)
    popupAlert({
      type: 'error',
      message: networkError.message,
    })
  }
  if (graphQLErrors?.some((error) => getApolloErrorCode(error) === 'UNAUTHENTICATED')) {
    const [refreshToken, storage] = getRefreshToken()
    if (operation.operationName === 'RefreshToken' || !refreshToken) {
      authEventEmitter.emit('logout')
    } else {
      return fromPromise(updateAccessToken(refreshToken, storage))
        .filter(Boolean)
        .flatMap(() => {
          operation.setContext({
            headers: {
              ...operation.getContext().headers,
              authorization: getToken(),
            },
          })
          return forward(operation)
        })
    }
  }
})

export default function useCreateApollo() {
  return useMemo(() => {
    apolloClient = new ApolloClient({
      link: errorMiddleware.concat(concat(authMiddleware, httpLink)),
      cache: new InMemoryCache({
        typePolicies,
      }),
      connectToDevTools: true,
    })
    return apolloClient
  }, [])
}

function updateAccessToken(refreshToken: string, storage: Storage) {
  if (activeUpdateToken) return activeUpdateToken

  activeUpdateToken = apolloClient
    .mutate<Pick<Mutation, 'refreshToken'>, MutationRefreshTokenArgs>({
      mutation: gql`
        mutation RefreshToken($refresh_token: String!) {
          refreshToken(refresh_token: $refresh_token) {
            access_token
            refresh_token
          }
        }
      `,
      variables: {
        refresh_token: refreshToken,
      },
    })
    .then((res) => {
      const { access_token, refresh_token } = res.data?.refreshToken!
      setToken(storage, access_token, refresh_token)
      return true
    })
    .catch((err) => {
      console.error(err)
      return false
    })
    .finally(() => {
      activeUpdateToken = undefined
    })

  return activeUpdateToken
}
