import loadable from '@loadable/component'
import { Route, RouteProps } from 'react-router-dom'
import { useEffect, useLayoutEffect } from 'react'
import ensureArray from 'utils/ensureArray'

export interface AsyncPageProps {
  page: string
  [k: string]: any
}

const AsyncPage = loadable<AsyncPageProps>((props) => import(`pages/${props.page}`), {})

export function usePreloadPage(pages: MaybeArray<string>) {
  useLayoutEffect(() => {
    ensureArray(pages).forEach((page) => import(`pages/${page}`))
  }, [])
}

interface AsyncRouteProps extends Omit<RouteProps, 'render' | 'children' | 'component'> {
  page: string
  componentProps?: any
  preload?: boolean
}

function AsyncRoute({ page, componentProps, preload, ...routerProps }: AsyncRouteProps) {
  useEffect(() => {
    if (preload) {
      AsyncPage.preload({ page })
    }
  }, [])
  return (
    <Route {...routerProps}>
      <AsyncPage key={page} page={page} {...componentProps} />
    </Route>
  )
}

export default AsyncRoute
