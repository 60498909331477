import { CSSProperties, memo, ReactNode } from 'react'
import { LayoutMenuSC, LayoutMenuSCProps } from './styled'
import SiteLogo from 'components/ui/SiteLogo'
import Link from 'components/ui/Link'

export interface LayoutMenuProps extends LayoutMenuSCProps {
  className?: string
  style?: CSSProperties
  menu: ReactNode
  actions: ReactNode
  logoHref?: string
}

function LayoutMenu({ className, style, menu, actions, logoHref }: LayoutMenuProps) {
  return (
    <LayoutMenuSC className={className} style={style}>
      <div className='left'>
        {logoHref ? (
          <Link to={logoHref}>
            <SiteLogo />
          </Link>
        ) : (
          <SiteLogo />
        )}
        <div className='menu'>{menu}</div>
      </div>
      <div className='actions'>{actions}</div>
    </LayoutMenuSC>
  )
}

export default memo(LayoutMenu)
