import { TypePolicies } from '@apollo/client'

const typePolicies: TypePolicies = {
  Query: {
    fields: {
      userList: {
        keyArgs: false,
        merge(existing, incoming, { args }) {
          if (!args?.input.page) return incoming
          return {
            ...incoming,
            data: [...(existing?.data ?? []), ...incoming.data],
          }
        },
      },
      clientProductList: {
        keyArgs: false,
        merge(existing, incoming, { args }) {
          if (!args?.input.page) return incoming
          return {
            ...incoming,
            data: [...(existing?.data ?? []), ...incoming.data],
          }
        },
      },
      notificationList: {
        keyArgs: false,
        merge(existing, incoming, { args }) {
          if (!args?.input.page) return incoming
          return {
            ...incoming,
            data: [...(existing?.data ?? []), ...incoming.data],
          }
        },
      },
      mediaList: {
        keyArgs: ['input', ['user_id']],
        merge(existing, incoming, { args }) {
          if (!args?.input.page) return incoming
          return {
            ...incoming,
            data: [...(existing?.data ?? []), ...incoming.data],
          }
        },
      },
    },
  },
}

export default typePolicies
