import styled from 'styled-components'

export const TextWrapSC = styled.div`
  display: flex;
  flex: 1 1;
  overflow-wrap: anywhere;
  word-break: break-word;
  white-space: normal;
  hyphens: auto;
`
